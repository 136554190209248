import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import { Trail, animated } from 'react-spring' 
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import socialImg from '../content/interviews/InterviewPageSocialThumb.jpg'



const Interviews = ({data}) => {

  return (
    <Layout>
      <Helmet>
        <title>Picture North | Interviews</title>
        <meta property="og:title" content='Picture North | Interviews' />
        <meta property="og:description" content='Curated interviews.' />
        <meta property="og:image" content={data.site.siteMetadata.siteUrl+socialImg} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={'picture_north'} />
        <meta name="twitter:title" content='Picture North | Constellations' />
        <meta name="twitter:description" content='Interviews with top diverse talent from around the world.' />
        <meta name="twitter:image" content={data.site.siteMetadata.siteUrl+socialImg} />

      </Helmet>
      <div className='directors-page-header text-center'>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Interviews</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="director-list interview-list">
        <Trail
          keys={data.interviews.edges.map( ({node}) => node.frontmatter.title )}
          from={{opacity:0 }}
          to={{ opacity: 1 }}
        >
          {data.interviews.edges.map( ({node}) => props => (
              <div style={props} key={node.id} className='director-listing'>
                  <Link className='dir-image-wrap' to={node.fields.slug} >
                    <Img fluid={node.frontmatter.headshot.childImageSharp.fluid} alt={node.title}/>
                  </Link>
                  <div className="dir-info">
                    <Link to={node.fields.slug} >
                      <h2>{node.frontmatter.title}</h2>
                    </Link>
                  </div>
              </div>
          ))}
        </Trail>
      </div>
    </Layout>
  )
}

export default Interviews;

export const interviewsQuery = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
          }
      
        interviews: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/interviews/"}},sort: { order: ASC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              fields {
                  slug
              }
              frontmatter {
                title
                subTitle
                expertise
                expObj
                headshot {
                    childImageSharp{
                        fluid(maxWidth : 1000, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                    publicURL
                }
              }
            }
          }
        }
      }
`